import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NearMeIcon from "@mui/icons-material/NearMe";
import SportsSharpIcon from "@mui/icons-material/SportsSharp";
import { CiStar } from "react-icons/ci";
import SearchIcon from "../../assets/images/search-icon.svg";
import TuneIcon from "@mui/icons-material/Tune";
import Navbar from "../../component/common/NavbarProfile";
import { Card, Select } from "antd";
import { fetchUserDatasApi } from "../../handlers/myaccount/api";
import image from "../../assets/images/cardimage.png";
import defaultPitch from "../../assets/images/pitch default.jpeg";
import { venueListingPage } from "../../handlers/common/api";
import { getActivity, getActivityResult } from "../../handlers/Search";
import { toast } from "react-toastify";

import PhoneNumberModal from '../CartViewScreen/Phonenumbermodel';

const Venue = () => {
  const [venueData, setVenueData] = useState([]);
  const [venueImages, setVenueImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activity, setActivity] = useState("");
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [searchActivityOptions, setSearchActivityOptions] = useState([
    { value: "All Events", label: "All Events" },
  ]);
  const [selectedSearchActivity, setSelectedSearchActivity] = useState();
  const [selectedSearchActivityResult, setSelectedSearchActivityResult] =
    useState([]);
  const [isphoneModalVisible, setIsphoneModalVisible] = useState(false);
  const [
    selectedSearchActivityResultDisplay,
    setSelectedSearchActivityResultDisplay,
  ] = useState([]);
  const [path, setPath] = useState("");

  useEffect(() => {
    const currentPath = window.location.pathname;
    setPath(currentPath);
  }, []);



  useEffect(() => {
    const checkTokenAndFetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          // Replace with your actual API function
          const userData = await fetchUserDatasApi(token);
          console.log("userData", userData);

          if (userData.email === "") {
            // toast.error("Your session has expired. Please log in again.");
            localStorage.removeItem("token");
          } else if(userData.phone==""){
            setIsphoneModalVisible(true);

          }
        } catch (error) {
          console.error(error.message);
        }
      }
    };

    checkTokenAndFetchUserData();
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);
  
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchVenueData();
  }, []);

  const handleVenueSearchChange = (event = null) => {
    const value = event?.target ? event.target.value : searchTerm;
    console.log("value", value);
    if (!value) {
      setSearchTerm();

      const arr = selectedSearchActivityResult;
      console.log(arr);
      setSelectedSearchActivityResultDisplay(arr);
      return;
    }

    setSearchTerm(value);
    console.log(selectedSearchActivityResult);
    const arr = selectedSearchActivityResult?.filter((item) =>
      item?.venue_name?.toLowerCase().includes(value?.toLowerCase())
    );
    console.log(arr);
    setSelectedSearchActivityResultDisplay(arr);
  };

  const handleVenueClick = (venue) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${venue.address}`
    );
  };

  const handleActivityChange = (event) => {
    setActivity(event.target.value);
  };

  // const handleSearch = () => {
  //   if (!venueData.data) return;
  //   const filteredData = venueData.data.filter((venue) => {
  //     if (!venue || !venue.name || !venue.activity) return false;
  //     return (
  //       venue.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //       venue.activity.toLowerCase().includes(activity.toLowerCase())
  //     );
  //   });
  //   setVenueData({ ...venueData, data: filteredData });
  // };

  const fetchVenueData = async () => {
    try {
      const response = await fetch(
        `${venueListingPage}?fields=["name","address","venue_name","base_url","venue_image","publish"]&limit_page_length=1000&filters=[["publish", "=", "1"]]`
      );
      const datas = await response.json();
      setVenueData(datas);
      setVenueImages(
        datas.map((venue) => `${venue.base_url}${venue.venue_image}`)
      );
    } catch (error) {
      console.error("Error fetching venueData:", error);
    }
  };
  const SearchActivity = async () => {
    const res = await getActivity();
    if (res.status === 200) {
      console.log("testing", res);
      const arr = [];
      const receivableAccountList = res.data.data.event_names;
      arr.push({ value: "All Events", label: "All Events" });
      for (let i = 0; i < receivableAccountList?.length; i++) {
        if (receivableAccountList[i].name == "Maintenance Booking") {
        } else {
          arr.push({
            value: receivableAccountList[i].name,
            label: receivableAccountList[i].name,
          });
        }
      }

      setSearchActivityOptions(arr);
    }
  };

  const handleToAllEvents = () => {
    setSearchTerm("");
  };
  const searchActivityResult = async () => {
    try {
      if (selectedSearchActivity === "All Events" || !selectedSearchActivity) {
        handleToAllEvents();
        setSelectedSearchActivityResult(venueData.data);
      } else {
        console.log("maintain", selectedSearchActivity);
        const res = await getActivityResult(selectedSearchActivity);
        if (res.status === 200) {
          console.log("test", res);
          let arr = [];
          arr = res.data.data.event_names;
          console.log(res.data.data.event_names);
          const eventNames2 = new Set(
            res.data.data.event_names.map((event) =>
              event.name.split("-")[0].trim()
            )
          );
          console.log("imp", eventNames2);
          console.log("imp", venueData.data);
          // Filter the venueData based on the base names
          arr = venueData.data.filter((item) => eventNames2.has(item.name));

          console.log(arr);
          setSelectedSearchActivityResult(arr);
        }
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(selectedSearchActivity);
    searchActivityResult();
  }, [selectedSearchActivity, venueData]);

  useEffect(() => {
    console.log(selectedSearchActivityResult);
  }, [selectedSearchActivityResult]);

  useEffect(() => {
    console.log(selectedSearchActivityResultDisplay);
  }, [selectedSearchActivityResultDisplay]);

  useEffect(() => {
    SearchActivity();
    handleVenueSearchChange();
  }, []);

  useEffect(() => {
    handleVenueSearchChange();
  }, [selectedSearchActivityResult]);

  const VenueCard = ({ venue }) => {
    const [showFullTitle, setShowFullTitle] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const toggleFavorite = () => {
      setIsFavorite(!isFavorite);
    };

    return (
      <div className="relative flex">
        <Card
          hoverable
          className="venue-card flex flex-col justify-center rounded-lg w-80 m-4"
          style={{
            overflow: "hidden",
            boxShadow: "0px 4px 10px 0px #0000001A",
          }}
          cover={
            <Link to={`/venue-listing-page/venue/${venue.name}`}>
              <div className="venue_img">
                <img
                  src={`${venue.base_url}${venue.venue_image}`}
                  alt={venue.name}
                  className="w-full object-cover rounded-t-lg"
                  onError={(e) => {
                    e.target.src = defaultPitch;
                  }}
                />
                {/* <div className="absolute bg-[#267E3E] items-center w-15 flex bottom-1 left-1 text-sm text-white rounded-md p-1">
                  4.4
                  <CiStar />
                </div> */}
              </div>
            </Link>
          }
        >
          <div className="absolute wishlist">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="26.13"
              height="22.79"
              className={`heart-icon cursor-pointer transition-colors duration-300 ${
                isFavorite ? "text-red-500" : "text-white"
              }`}
              onClick={toggleFavorite}
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </svg> */}
          </div>
          <div className="flex flex-col flex-grow">
            <div className="whitespace-pre-line venue-title">
              {venue.venue_name}
            </div>
            <p className="venue-detail">Address: {venue.address}</p>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Navbar />

      <div className="flex max-w-full justify-between px-6 lg:px-20 mt-8 items-start mx-auto searchbarFilter">
        <div className="flex mt-4 mb-4 nearMeSports">
          <div className="flex flex-col items-center relative">
            {/* <NearMeIcon />
            <div
              className="font-bold cursor-pointer"
              onClick={() =>
                setSelectedVenue(selectedVenue === null ? venueData.data : null)
              }
            >
              Venue
            </div> */}
            {selectedVenue && (
              <div className="card-index bg-white border rounded-md p-2">
                {venueData.data?.map((venue) => (
                  <div
                    key={venue.name}
                    onClick={() => handleVenueClick(venue)}
                    className="cursor-pointer hover:bg-gray-100 p-1"
                  >
                    {venue.venue_name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center searchBar">
          <div className="flex justify-between leading-tight items-center border text-center rounded-full border-gray-300 py-2.5 px-2.5">
            <div className="flex flex-col border-r border-gray-300 venueSearch">
              <label>Where</label>
              <input
                type="text"
                placeholder="Search Venue"
                className="text-center  ml-1 mr-2"
                value={searchTerm}
                onChange={handleVenueSearchChange}
              />
            </div>
            <div className="flex flex-col px-5 activitySearch">
              <label>Search Sport</label>
              <Select
                size="medium"
                placeholder="Select"
                defaultValue="All Events"
                options={searchActivityOptions}
                value={selectedSearchActivity}
                onChange={(value) => {
                  setSelectedSearchActivity(value);
                }}
              />
            </div>
            {/* Uncomment if search button is needed */}
            {/* <div className="flex items-center px-20 search-icon-btn">
            <button className="searchBtn" onClick={handleSearch}>
                <img src={SearchIcon} alt="Search" />
            </button>
        </div> */}
          </div>
        </div>

        <div className="flex ml-4">
          {/*  <div className="flex justify-between items-center ml-5 w-full border text-center rounded-full mt-5 border-gray-300 p-4 lg:p-3">
            <div className="flex justify-between mr-2">
              <div className="font-bold ">
                Filters
                <TuneIcon className="ml-2" />
              </div>
            </div>
          </div>*/}
        </div>
      </div>

      <div className="flex flex-wrap gap-4 max-w-full px-6 lg:px-14 justify-center mb-10">
        {selectedSearchActivityResultDisplay?.map((venue) => (
          <VenueCard key={venue.name} venue={venue} />
        ))}
      </div>

      <PhoneNumberModal
        isModalVisible={isphoneModalVisible}
        setIsModalVisible={setIsphoneModalVisible}
      />
    </>
  );
};

export default Venue;
