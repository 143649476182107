import React, { useEffect, useState } from "react";
import { getBookingHistory } from "../../handlers/myaccount/api";
import { useLocation } from 'react-router-dom';
// import Image from "../../assets/images/cardimage.png";
// import ProductImage from "../../assets/images/bat.jpg";

function Bookings() {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [sortOrder, setSortOrder] = useState("dateDesc");
  const [activeTab, setActiveTab] = useState("upcoming");
  const location = useLocation();

  const handleGetBookingHistory = async () => {
    const token = localStorage.getItem("token");
    const res = await getBookingHistory(token);
    if (res.status === 200) {
      console.log(res, "booking response");
      const arr = res.data.data.booking_details.map((booking) => ({
        name: booking.name,
        items: booking.items,
      }));

      console.log(arr, "booking response array")

      console.log(arr, "time issue");
      setBookingHistory(arr);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);
  
    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, [location]);

  const formatTime = (timeString) => {
    const hour = parseInt(timeString.substr(0, 2), 10);
    const minutes = timeString.substr(3, 2);
    // const ampm = hour >= 12 ? "PM" : "AM";
    // const hour12 = hour % 12 || 12;
    const formattedTime = `${hour.toString().padStart(2, "0")}:${minutes}`;
    return formattedTime;
  };

  useEffect(() => {
    handleGetBookingHistory();
  }, []);

  const currentDateTime = new Date();
  const upcomingBookings = bookingHistory.filter((booking) => {
    return booking.items.some(item => new Date(item.custom_start_date) >= currentDateTime);
  });

  const pastBookings = bookingHistory.filter((booking) => {
    return booking.items.every(item => new Date(item.custom_start_date) < currentDateTime);
  });


  const sortBookings = (bookings, order) => {


    console.log(bookings, order, "order-details")
    return bookings.sort((a, b) => {
      const aDate = new Date(a.items[0].custom_start_date);
      const bDate = new Date(b.items[0].custom_start_date);
      if (order === "dateAsc") {
        return aDate - bDate;
      } else if (order === "dateDesc") {
        return bDate - aDate;
      } else if (order === "priceAsc") {
        return a.items[0].amount - b.items[0].amount;
      } else if (order === "priceDesc") {
        return b.items[0].amount - a.items[0].amount;
      }
      return 0;
    });
  };

  const sortedUpcomingBookings = sortBookings(upcomingBookings, sortOrder);
  const sortedPastBookings = sortBookings(pastBookings, sortOrder);
  console.log(sortedPastBookings, "sorted past book");

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="relative flex flex-col">
      <div className="flex flex-col sm:flex-row justify-end  py-4 space-y-2 sm:space-y-0 sm:space-x-4">
        <select
          value={sortOrder}
          onChange={handleSortChange}
          className="px-4 py-2 border rounded-md w-full sm:w-auto"
        >
          <option value="dateAsc">Date (Ascending)</option>
          <option value="dateDesc">Date (Descending)</option>
          <option value="priceAsc">Price (Ascending)</option>
          <option value="priceDesc">Price (Descending)</option>
        </select>
      </div>

      <div className="mb-4">
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            className={`px-4 py-2 rounded-md w-full sm:w-auto ${activeTab === "upcoming" ? "bg-red-500 text-white" : "bg-gray-200"}`}
            onClick={() => handleTabChange("upcoming")}
          >
            Upcoming Events
          </button>
          <button
            className={`px-4 py-2 rounded-md w-full sm:w-auto ${activeTab === "past" ? "bg-red-500 text-white" : "bg-gray-200"}`}
            onClick={() => handleTabChange("past")}
          >
            Past Events
          </button>
        </div>
      </div>

      <div>
        {activeTab === "upcoming" ? (
          <div>
            <h2 className="text-xl font-semibold mb-4">Upcoming Events</h2>
            {sortedUpcomingBookings.length === 0 ? (
              <p className="lg:px-14 mb-4">No upcoming events.</p>
            ) : (
              sortedUpcomingBookings.map((bookingitem, index) => (
                <div
                  key={index}
                  className="order-box grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4  max-w-full px-6 lg:px-14 justify-center"
                >
                  {bookingitem.items.map((item, itemIndex) => (
                    <div key={itemIndex} className="booking-item-box">
                      {item.item_group === "Services" ? (
                        <div className="booking-item">

                          <div className="xxs:ml-3">
                            <div className="text-black text-md">
                              {item.custom_venue.replace("-", "|")}
                              {" | "}
                              {item.custom_pitch.split("-").pop().trim()}
                              {" | "}
                              {item.custom_booking_for.split("-").pop().trim()}
                            </div>
                            <div className="text-gray-400">
                              Date: {item.custom_start_date.split(" ")[0]}
                            </div>
                            <div className="text-gray-400">
                              Time: {item.custom_start_date.split(" ")[1]} -{" "}
                              {item.custom_end_date.split(" ")[1]}
                            </div>
                            {/* <div className="">$ {item.amount} SGD</div> */}
                          </div>
                        </div>
                      ) : (
                        <div className="booking-item  xxs:ml-3">
                          {/* <img
                            src={item.image || ProductImage}
                            alt={item.item_name}
                            style={{ width: "100px", height: "100%" }}
                          /> */}
                          <div className=" ">
                            <div className="text-black text-md">
                              {item.item_name}
                            </div>
                            <div className="text-gray-400  ">
                              Quantity: {item.custom_actual_quantity}
                            </div>
                            {/* <div className=""> $ {item.amount} SGD</div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>
        ) : (
          <div>
            <h2 className="text-xl font-semibold mb-4">Past Events</h2>
            {sortedPastBookings.length === 0 ? (
              <p className=" mb-4">No past events.</p>
            ) : (
              sortedPastBookings.map((bookingitem, index) => {

                // const totalAmount = bookingitem.items.reduce((total, item) => total + item.amount, 0);

                return (
                  <div
                    key={index}
                    className="order-box grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 max-w-full px-6 lg:px-14 justify-center"
                  >
                    {bookingitem.items.map((item, itemIndex) => (
                      <div key={itemIndex} className="booking-item-box">
                        {item.item_group === "Services" ? (
                          <div className="booking-item">
                            <div className="xxs:ml-3">
                              <div className="text-black text-md">
                                {item.custom_venue.replace("-", "|")}
                                {" | "}
                                {item.custom_pitch.split("-").pop().trim()}
                                {" | "}
                                {item.custom_booking_for.split("-").pop().trim()}
                              </div>
                              <div className="text-gray-400">
                                Date: {item.custom_start_date.split(" ")[0]}
                              </div>
                              <div className="text-gray-400">
                                Time: {formatTime(item.custom_start_date.split(" ")[1])} - {formatTime(item.custom_end_date.split(" ")[1])}
                              </div>
                              {/* <div className="">$ {item.amount} SGD</div> */}
                            </div>
                          </div>
                        ) : (
                          <div className="booking-item  xxs:ml-3">
                            <div className="">
                              <div className="text-black text-md">
                                {item.item_name}
                              </div>
                              <div className="text-gray-400">
                                Quantity: {item.custom_actual_quantity}
                              </div>
                              {/* <div className="text-gray-400 mb-2">$ {item.amount} SGD</div> */}
                              {/* <div className="">$ {item.amount} SGD</div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}


                    {/* <div className="text-black font-bold">
                    Total: $ {totalAmount} SGD
                  </div> */}
                  </div>
                );
              })
            )}
          </div>

        )}
      </div>
    </div>
  );
}

export default Bookings;
